.bloc-financement .gagner-temps {
  font-size: 2.2rem;
  font-weight: bold;
  color: $third-theme-color;
}

.bloc-financement .pastille {
  padding: 2px 9px;
  margin-right: 5px;
}

#carouselImmo .card {
  border: none;
}
#carouselImmo .card {
  border-radius: 10px;
  box-shadow: 0 0 10px $ombre-rose;
  max-width: 80%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
#carouselImmo .card .card-body {
  height: 215px;
  font-size: 1.4rem;
}

#carouselImmo .card .card-body .card-title {
  font-size: 1.68em;
  min-height: 48px;
}

#carouselImmo .card .card-body .bloc-text {
  height: 192px;
}

#carouselImmo .card .card-body .bloc-button {
  z-index: 2;
  position: relative;
}

#carouselImmo .card .card-body .bloc-button .button-fourth {
  font-size: 1.2rem;
  z-index: 2;
}
#carouselImmo .card .card-img-top {
  border-radius: 0 0 10px 10px;
}
#carouselImmo .card .card-img {
  border-radius: 0 0 10px 10px;
}

#carouselImmo .card .card-img-red {
  position: relative;
}

#carouselImmo .card .card-img-red:before {
  border-radius: 0 0 10px 10px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,0,0, 0.3);
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

#carouselImmo .card .card-img-top {
  border-radius: 0 0 10px 10px;
  height: 160px;
}
#carouselImmo .card .card-img-top img {
  border-radius: 0 0 10px 10px;
  height: 160px;
  object-fit: cover;
}

.bloc-financement .separator {
  width: 50%;
}

.bloc-financement p {
  font-size: 1.6rem;
}

.bloc-profil {
  border-radius: 20px;
}

.bloc-profil a:hover {
  color: $third-theme-color;
}

.bloc-profil form, .bloc-profil form input[type="submit"] {
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .bloc-profil form {
    height: 100%;
    padding-top: 16px;
    text-align: center;
  }

  .bloc-profil p {
    height: 100%;
    padding-top: 8px;
  }

  #carouselImmo .card .card-body .bloc-button .button-fourth {
    font-size: 1rem;
  }
  #carouselImmo .hide-tablet {
    display: none;
  }

  #carouselImmo .card  .card-body .card-title {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {

  #carouselImmo .card .card-body .bloc-button .button-fourth {
    font-size: 1.4rem;
  }

  #carouselImmo .hide-tablet {
    display: initial;
  }

  .bloc-profil {
    font-size: 1.5rem;
  }

  .bloc-profil form, .bloc-profil form input[type="submit"] {
    font-size: 1.4rem;
  }

  #carouselImmo .card  .card-body .card-title {
    font-size: 2.2rem;
  }
}


/**
 * CAROUSEL
 */
@media (min-width: 576px) and (max-width: 767px) {
  /* Show 3rd slide on sm if col-sm-6*/
  #carouselImmo .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;  /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Show 4th slide on md if col-md-4*/
  #carouselImmo .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;  /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}
/* MD */
@media (min-width: 768px) {
  #carouselImmo .carousel-item {
    margin-right: 0;
  }
  /* show 3rd of 3 item slide */
  #carouselImmo .carousel-inner .active + .carousel-item,
  #carouselImmo .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  #carouselImmo .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  #carouselImmo .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
  #carouselImmo .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }
  #carouselImmo .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  #carouselImmo .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  #carouselImmo .carousel-item-next.carousel-item-left,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction */
  #carouselImmo .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  #carouselImmo .carousel-item-prev.carousel-item-right,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}
/* LG */
@media (min-width: 991px) {
  /* show 4th item */
  #carouselImmo .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
  }
  #carouselImmo .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none;
  }
  /* Show 5th slide on lg if col-lg-3 */
  #carouselImmo .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* left or forward direction */
  #carouselImmo .carousel-item-next.carousel-item-left,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction //t - previous slide direction last item animation fix */
  #carouselImmo .carousel-item-prev.carousel-item-right,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
  #carouselImmo .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}
