#guide-block {
  & .card {
    border-radius: 10px;
    box-shadow: 0 0 5px #dca9b7;
    //max-width: 80%;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}