/**
 * Header
 */

/**
 * Barre accès connexion
 */
.acces-nav a.text-acces-connexion:hover {
  color: white;
}


/**
 * Menu principal
 */
/*
.dropdown:hover>.dropdown-menu {
  display: block;
}*/

.bloc-header {
  min-height: 90px;
  font-size: 1.6rem;
}
.navbar-brand {
  max-width: 75%;
}
img.logo {
  max-width: 100%;
}

.title-h1 {
  font-size: 0.1px;
  color: $default-white;
}

.bloc-header .dropdown-menu .row.has-shadow {
  border-bottom: solid 1px $fond-acces-connexion;
}

.bloc-header .row {
  margin-left: 0;
  margin-right: 0;
}

.fa.fa-navicon, .fa-angle-right {
  color: $first-theme-color;
}

.navbar-toggler {
  border: none;
  font-size: 2rem;
}

.dropdown-menu {
  border: none;
  font-size: 1.4rem;
  padding: 0;
}

.dropdown-menu .title {
  font-size: 1.8rem;
}

.dropdown-menu > .row > div {
  border-radius: 5px;
  box-shadow: 1px 0 7px $grey-500;
}

.accroche {
  margin-top: 15px;
  color: $first-theme-color;
}

.accroche-menu {
  margin-top: 15px;
  color: $first-theme-color;
  text-shadow: $default-white 0 0 1px;
}

.accroche.vert {
  color: $sixth-theme-color;
}

.accroche-menu.vert {
  color: $sixth-theme-color;
}

.accroche.gris {
  color: $grey-700;
}

.percent {
  font-size: 6rem;
}

.bloc-header .dropdown-menu img {
  max-width: 22px;
}

.bloc-header .dropdown-menu li:not(:last-child) {
  //border-bottom: 2px solid $fond-acces-connexion;
  margin-bottom: 6px;
}

.bloc-header .dropdown-menu i.fa.fa-angle-right {
  padding-top: 5px;
}

.bloc-header .dropdown-menu i.fa.fa-piggy-bank {
  font-size: 3rem;
  color: $first-theme-color;
}

.btn-profil img {
  display: none;
}

.bloc-header .bulle-pink {
  background-color: $second-theme-color;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  padding: 15px 15px;
  display: block;
  margin: auto;
}

.bloc-header .bulle-green {
  background-color: $fifth-theme-color;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  padding: 15px 15px;
  display: block;
  margin: auto;
}

.bloc-header .dropdown-menu .bulle-pink img,
.bloc-header .dropdown-menu .bulle-green img
{
  max-width: 100%;
}

.bloc-header .bulle-pink.bulle-left {
  background-color: $second-theme-color;
  display: inline-block;
}

.bloc-header .bulle-green.bulle-left {
  background-color: $fifth-theme-color;
  display: inline-block;
}

.bloc-header .bulle-pink.text,
.bloc-header .bulle-green.text {
  padding: 7px 15px;
}

.bloc-header .bulle-pink.text .icone-menu {
  font-size: 3.2rem;
  color: $first-theme-color;
  vertical-align: middle;
}

.bloc-header .bulle-green.text .icone-menu {
  font-size: 3.2rem;
  color: $sixth-theme-color;
  fill: $sixth-theme-color;
  vertical-align: middle;
}

.bloc-header .bulle-green.text .icone-menu img {
  fill: $sixth-theme-color;
}

.bloc-header .bulle-pink.text:hover, .bloc-header .bulle-pink.text .icone-menu:hover {
  text-decoration: none;
}
.menu-separator {
  width: 60%;
  height: 1px;
  background-color: $grey-600;
  margin: auto;
}

.menu-separator.mini {
  width: 31%;
}

.bloc-menu-button {
  margin-top: 30px;
  height: 50px;
}

.bulle-pink + a {
  position: relative;
}

.bloc-header iframe {
  width: 75%;
}

.menu-bloc-image {
  min-height: 280px;
  max-height: 290px;
}

.menu-bloc-image::before{
  background-color: rgba(255, 255, 255, 0.7);
  content: '';
  padding: 15px;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
}

.bloc-image-pret-immo {
  background: url('./../../../static/images/base/sous-menu/bg-pret-immobilier.jpg') no-repeat center;
  background-size: 100%;
}

.bloc-image-credit-conso {
  background: url('./../../../static/images/base/sous-menu/bg-credit-consommation.jpg') no-repeat center;
  background-size: 100%;
}

.bloc-image-rachat {
  background: url('./../../../static/images/base/sous-menu/bg-rachat-credit.jpg') no-repeat center;
  background-size: 100%;
}

.bloc-image-ade {
  background: url('./../../../static/images/base/sous-menu/bg-assurance-emprunteur.jpg') no-repeat center;
  background-size: 100%;
}

.bloc-image-mrh {
  background: url('./../../../static/images/base/sous-menu/bg-assurance-habitation.jpg') no-repeat center;
  background-size: 100%;
}

.bloc-image-prevoyance {
  background: url('./../../../static/images/base/sous-menu/bg-prevoyance-familiale.jpg') no-repeat center;
  background-size: 100%;
}

.liens-list-menu {
  color: $third-theme-color;
}
.liens-list-menu:hover {
  color: $third-theme-color;
}

@media (min-width: 375px) {
   .bg-color-xs input[type=range]{
    background-color: $first-theme-color;
  }
  .fs15xs{
    font-size: 15px;
  }

  .fs12xs{
    font-size: 12px;
  }
}

@media (min-width: 768px) {

  .menu-pret, .menu-rachat, .menu-conso {
    font-size: 1.6rem;
  }

  .btn-profil img {
    max-width: 18px;
  }

  .dropdown-menu .btn-assurance {
    font-size: 1.4rem;
  }

  .too-long-md {
    font-size: 1.6rem;
  }

  .menu-annonce .col-annonce:not(:first-child) {
    border-left: solid 2px;
  }

  .bloc-header iframe {
    height: 200px;
  }
}

@media (min-width: 992px) {
  .dropdown-menu .title {
    font-size: 2.2rem;
  }

  .btn-profil img {
    display: initial;
  }

  .too-long-md {
    font-size: 1.4rem;
  }

  .dropdown-menu .nb-annonces {
    font-size: 2.2rem;
  }

  .bloc-header iframe {
    height: 290px;
  }
}

@media (min-width: 1200px) {

  .dropdown-menu .title.annonces {
    font-size: 1.6rem;
  }

  .dropdown-menu .nb-annonces {
    font-size: 1.6rem;
  }

  .too-long {
    font-size: 1.4rem;
  }
  .dropdown-menu .btn-assurance {
    font-size: 1.8rem;
  }

  .bloc-header iframe {
    height: 250px;
  }

  .menu-bloc-image {
    min-height: 280px;
    max-height: 100%;
  }

}
