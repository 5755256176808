.home-produit-assurance .separator {
  width: 50%;
}

.home-produit-assurance p {
  font-size: 1.6rem;
}

.home-produit-assurance .bloc-assurance  {
  border-radius: 50px;
  min-height: 80px;
  padding: 10px 5px;
  margin: 20px 0;
}

.home-assurance-produit .bloc-image img {
  width: 75%;
}

.home-assurance-garantie .text-center {
  text-decoration: underline;
}

.home-assurance-garantie a {
  color: $third-theme-color;
}

.home-assurance-garantie .fa-check {
  border: 1px $gris solid;
  border-radius: 3px;
  margin-right: 5px;
}

.bloc-assurance .pastille {
  padding: 1px 7px;
  margin-top: 11px;
}

.bloc-assurance .titre-assurance {
  font-size: 2rem;
}

@media (min-width: 768px) {
  .home-assurance-produit .bloc-image img {
    width: 100%;
  }

  .home-produit-assurance h3 {
    font-size: 1.6rem;
    padding-bottom: 1em;
  }
}

