.liste-actus-item:hover {
  text-decoration: none;
}

#carouselActus {
  height: 600px;
  background: $first-theme-color url($images-path + 'base/forme-1.svg') left no-repeat;
}

#carouselActus .actualite-item-image {
  height: 200px;
}

#carouselActus .actualite-image {
  position: relative;
  border: 10px $default-white solid;
  box-shadow: 10px -10px 0px rgba(0, 0, 0, 0.4);
  max-height: 200px;
  max-width: 75%;
}

#carouselActus .actualite-item-content {
  max-width: 75%;
  margin: auto;
}

#carouselActus .actualite-item-content h4 {
  font-size: 1.8rem;
}

#carouselActus .toutes-actus a:hover {
  color: $default-white;
}

#carouselActus .actualite-item-content a span {
  color: $text-theme !important;
}

@media (min-width: 768px) {

  .liste-actus-item img {
    max-width: 230px;
  }

  #carouselActus {
    height: 350px;
  }

  #carouselActus .actualite-item-image {
    margin-right: 0;
    padding-right: 0;
  }

  #carouselActus .actualite-item-content {
    padding-right: 75px;
  }
}

@media (min-width: 992px){

  #carouselActus {
    height: 400px;
  }

  #carouselActus .actualite-item-image {
    height: 250px;
  }

  #carouselActus .actualite-image {
    max-height: 250px;
  }

  #carouselActus .actu-auteur {
    font-size: 1.3rem;
  }

}

@media (min-width: 1200px) {
  #carouselActus {
    height: 400px;
  }

  #carouselActus .actualite-item-image {
    height: 290px;
  }

  #carouselActus .actualite-image {
    max-height: 290px;
  }
}
