.simulation-page .annonce .case {
  text-align: center;
  width: 130px;
  height: 90px;
  display: inline-block;
  margin-bottom: 10px;
}

#carouselAnnonce .carousel-inner {
  height: 400px;
}

#carouselAnnonce .carousel-inner .carousel-item {
  height: 430px;
  text-align: center;
}

#carouselAnnonce .carousel-control-prev i, #carouselAnnonce .carousel-control-next i {
  font-size: 4rem;
  color: $first-theme-color;
}

.simulation-page .annonce #carouselImmo .card .card-body .bloc-button .button-fourth {
  font-size: 1.2rem;
}

.simulation-page .annonce #carouselImmo .card .card-body .bloc-button .simulation {
  display: initial;
}

#carouselAnnonce img {
  object-fit: contain;
  max-height: 400px;
}

#carouselAnnonce .carousel-indicators {
  bottom: -45px;
}

#carouselAnnonce .carousel-indicators li {
  background-color: $first-theme-color;
  width: 20px;
}

.simulation-page .annonce-produit {
  border: solid $first-theme-color;
  border-radius: 30px;
}

.simulation-page .annonce-produit h3 {
  text-align: center;
  font-size: 2.2rem;
}
.simulation-page .annonce-produit .font-weight-bold{
  font-size: 1.8rem;
}

.simulation-page .annonce #carouselImmo .card .card-body {
  height: 315px;
}

.simulation-page .annonce #carouselImmo .card .card-body .bloc-text {
  height: 272px;
  font-size: 1.6rem;
}

.simulation-page .annonce .description h4 {
  font-size: 1.6rem;
}


.modal .row > .column {
  padding: 0 8px;
}

.modal .row:after {
  content: "";
  display: table;
  clear: both;
}

/* Create four equal columns that floats next to eachother */
.modal .column {
  float: left;
  width: 15%;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 20;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Content */
.modal .modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  width: 90%;
  //max-width: 1024px;
  background-color: transparent;
  border: none;
}

/* The Close Button */
.modal .close {
  color: $second-theme-color;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  opacity: 0.8;
}

.modal .close:hover,
.modal .close:focus {
  color: $first-theme-color;
  text-decoration: none;
  cursor: pointer;
  text-shadow: none;
}

/* Hide the slides by default */
.mySlides {
  display: none;
}

.modal-content .carousel-indicators {
  bottom: -40px;
}

.modal-content .carousel-indicators li {
  background-color: $first-theme-color;
}

/* Next & previous buttons */
.modal-content .prev,
.modal-content .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 40px 25px;
  margin-top: -50px;
  color: $second-theme-color;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.modal-content .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.modal-content .prev:hover,
.modal-content .next:hover {
  //background-color: rgba(0, 0, 0, 0.8);
  color: $first-theme-color;
}

/* Number text (1/3 etc) */
.numbertext {
  color: $default-white;
  font-size: 1.6rem;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Caption text */
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#carouselAnnonces-similaires {
  height: 470px;
}

#carouselAnnonces-similaires .carousel-inner {
  height: 470px;
}

#carouselAnnonces-similaires .bloc-img {
  height: 250px;
  text-align: center;
}

#carouselAnnonces-similaires .bloc-img .image-annonce-similaire {
  max-height: 250px;
  width: auto;
}

@media (min-width: 576px) {
  #carouselAnnonces-similaires {
    height: 380px;
  }

  #carouselAnnonces-similaires .carousel-inner {
    height: 380px;
  }

  #carouselAnnonces-similaires .bloc-img {
    height: 200px;
  }

  #carouselAnnonces-similaires .bloc-img .image-annonce-similaire {
    max-height: 200px;
    width: auto;
  }

  .simulation-page .annonce #carouselImmo .card .card-body .bloc-text {
    height: 272px;
  }

  .simulation-page .annonce .annonce-produit-detail a {
    font-size: 0.9em;
  }

}

@media (min-width: 768px) {
  .simulation-page .annonce #carouselImmo .card .bloc-text p {
    font-size: 1.3rem;
  }
  .simulation-page .annonce #carouselImmo .card .card-body .bloc-button .button-fourth {
    font-size: 1rem;
  }

  .simulation-page .annonce #carouselImmo .card .card-body .bloc-button .simulation {
    display: none;
  }

  #carouselAnnonces-similaires {
    height: 380px;
  }

  #carouselAnnonces-similaires .carousel-inner {
    height: 380px;
  }

  #carouselAnnonces-similaires .bloc-img {
    height: 180px;
  }

  #carouselAnnonces-similaires .bloc-img .image-annonce-similaire {
    max-height: 180px;
    width: auto;
  }

  .simulation-page .annonce #carouselImmo .card .card-body .bloc-text p {
    line-height: 140%;
  }


}

@media (min-width: 992px ) {

  .simulation-page .annonce #carouselImmo .card .card-body .bloc-button .button-fourth {
    font-size: 1.3rem;
  }
  .simulation-page .annonce #carouselImmo .card .card-body .bloc-text {
    height: 276px;
  }
  .simulation-page .annonce #carouselImmo .card .bloc-text p {
    font-size: 1.6rem;
  }
  .simulation-page .annonce #carouselImmo .card .card-body .bloc-button .simulation {
    display: initial;
  }

  #carouselAnnonces-similaires {
    height: 380px;
  }

  #carouselAnnonces-similaires .carousel-inner {
    height: 380px;
  }

  #carouselAnnonces-similaires .bloc-img {
    height: 190px;
  }

  .simulation-page .annonce #carouselImmo .card .card-body .bloc-text p {
    line-height: 140%;
  }

  .simulation-page .annonce .annonce-produit-detail a {
    font-size: 1.6rem;
  }

}
/**
 * CAROUSEL
 */
@media (min-width: 576px) and (max-width: 767px) {
  /* Show 3rd slide on sm if col-sm-6*/
  #carouselAnnonces-similaires .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;  /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Show 4th slide on md if col-md-4*/
  #carouselAnnonces-similaires .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;  /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 576px) {
  #carouselAnnonces-similaires .carousel-item {
    margin-right: 0;
  }
  /* show 2 items */
  #carouselAnnonces-similaires .carousel-inner .active + .carousel-item {
    display: block;
  }
  #carouselAnnonces-similaires .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  #carouselAnnonces-similaires .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none;
  }
  #carouselAnnonces-similaires .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  #carouselAnnonces-similaires .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item + .carousel-itemm {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* farthest right hidden item must be also positioned for animations */
  #carouselAnnonces-similaires .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* right or prev direction */
  #carouselAnnonces-similaires .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item  {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}
/* MD */
@media (min-width: 768px) {
  /* show 3rd of 3 item slide */
  #carouselAnnonces-similaires .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  #carouselAnnonces-similaires .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }
  #carouselAnnonces-similaires .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction */
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}
/* LG */
@media (min-width: 991px) {
  /* show 4th item */
  #carouselAnnonces-similaires .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
  }
  #carouselAnnonces-similaires .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none;
  }
  /* Show 5th slide on lg if col-lg-3 */
  #carouselAnnonces-similaires .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* left or forward direction */
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction //t - previous slide direction last item animation fix */
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
  #carouselAnnonces-similaires .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}
@media (max-width: 375px) {
  #carouselAnnonce{
    height: 260px;
  }
}
