/**
 * Définition des couleurs
 */
.text-theme-color {
  color: $text-theme
}

.text-white-color {
  color: $default-white;
}

.bg-white-color {
  background-color: $default-white;
}

.text-black-color {
  color: $default-dark;
}

.bg-text-theme-color {
  background: $text-theme
}

.text-first-theme-color {
  color: $first-theme-color;
}

.bg-first-theme-color {
  background-color: $first-theme-color;
}

.text-second-theme-color {
  color: $second-theme-color;
}

.bg-second-theme-color {
  background-color: $second-theme-color;
}

.text-third-theme-color {
  color: $third-theme-color;
}

.bg-third-theme-color {
  background-color: $third-theme-color !important;
}

.text-fourth-theme-color {
  color: $fourth-theme-color;
}

.bg-fourth-theme-color {
  background-color: $fourth-theme-color;
}

.text-fifth-theme-color {
  color: $fifth-theme-color;
}

.text-sixth-theme-color {
  color: $sixth-theme-color;
}

.bg-sixth-theme-color {
  background-color: $sixth-theme-color;
}

.text-gris {
  color: $fond-acces-connexion;
}

.bg-fifth-theme-color {
  background-color: $fifth-theme-color;
}

.text-acces-connexion {
  color: $acces-connexion;
}

.bg-access-connexion {
  background-color: $fond-acces-connexion;
}

.bg-btn-connexion {
  background-color: $sixth-theme-color;
}

a {
  color: $text-theme
}

a:hover {
  color: $text-theme
}

.fs14 {
  font-size: 1.4rem;
}

.fs16 {
  font-size: 1.6rem;
}

.fs18 {
  font-size: 1.8rem;
}

.fs20 {
  font-size: 2.0rem;
}

.fs22 {
  font-size: 2.2rem;
}

.fs24 {
  font-size: 2.4rem;
}

.fs26 {
  font-size: 2.6rem;
}

.fs28 {
  font-size: 2.8rem;
}

.fs30 {
  font-size: 3.0rem;
}
