.breadcrumb {
  font-size: 1.2rem;
}

.simulation-page {
  font-size: 1.6rem;
  line-height:initial;
}

.simulation-page .bloc-title {
  font-size: 1.6rem;
}

.simulation-page h1.title {
  font-size: 3.2rem;
}

.bloc-title p a, .bloc-content-text a, .bloc-how-to a,
.bloc-title p a:hover, .bloc-content-text a:hover, .bloc-how-to a:hover {
  color: $first-theme-color;
}

.simulation-page .pastille {
  padding: 1px 7px;
}
.simulation-page .pastille-text {
  font-size: 1.4rem;
}
.simulation-page .pastille-big-red {
  padding: 26px 16px;
}

.simulation-page .accroche {
  font-size: 2rem;
  min-height: 10em;
  vertical-align: middle;
}

.simulation-page .accroche .text-large {
  font-size: 2.4rem;
}

.simulation-page .accroche .pastille {
  padding: 19px 7px;
  vertical-align: middle;
}

.recherche-top p {
  text-align: center;
}

.simulation-page .step {
  text-align: center;
  margin-bottom: 20px;
  //font-weight: bold;
}

.simulation-page .steps .legende {
  font-weight: bold;
}

.simulation-page .image-step-bloc{
  height: 160px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.simulation-page .image-step-bloc img {
  max-height: 160px;
}

.simulation-page .know-more {
  width: 90%;
  min-height: 400px;
  margin: auto;
}

.simulation-page .know-more > div {
  margin-bottom: 70px;
}

.simulation-page .know-more .title-too-long {
  font-size: 1.77rem;
}

.simulation-page .know-more iframe {
  width: 100%;
  height: 100%;
  max-height: 360px;
}

.simulation-page .know-more .col-links {
  margin-top: 7em;
}

.simulation-page .bloc-content-text {
  font-size: 1.6rem;
  line-height:initial;
}

.simulation-page .bloc-content-text h2, .simulation-page .bloc-how-to h2 {
  font-size: 2.4rem;
  font-weight: bold;
  color: $first-theme-color;
  margin: 25px 0 20px 0;
}
.simulation-page .bloc-content-text h3, .simulation-page .bloc-how-to h3 {
  font-size: 2.1rem;
  color: $first-theme-color;
  margin: 20px 0 15px 0;
}

.simulation-page .bloc-content-text .img-content-text {
  box-shadow: 0 0 1rem $gris-middle;
  margin: 20px auto;
  width: 320px;
}

.simulation-page .bloc-content-text .img-center-content-text {
  display: block;
  box-shadow: 0 0 1rem $gris-middle;
  margin: 15px auto;
  width: 100%;
  max-width: 450px;
}

.simulation-page .bloc-content-text .img-center-content-text.no-shadow {
  box-shadow: none;
}

.simulation-page .bloc-content-text a {
  color: $first-theme-color;
}
.simulation-page .bloc-content-text a:hover {
  color: $first-theme-color;
}

.simulation-page .calculette {
  border-radius: 20px;
  border: 2px solid $default-white;
  box-shadow: 0 0 1rem $gris-middle;
}

.simulation-page .calculette img {
  width: 60px;
}

.simulation-page .bloc-title .wave {
  top: -30px;
}

.simulation-page .nav .nav-link, .simulation-page .nav .nav-link:hover, .simulation-page .nav .nav-link.active, .simulation-page .nav .nav-link.active:hover {
  border: none;
  border-color: transparent;
}

.simulation-page .nav .nav-item {
  width: 100%;
  height: 205px;
}

.simulation-page .omi-acquereur, .simulation-page .omi-immo {
  line-height: 200%;
}

/* témoignages */

.simulation-page .temoignages .temoignage .bloc-image img {
  width: 95%;
}

.simulation-page .temoignages .temoignage .bloc-text {
  border-radius: 50px;
  border: 1px solid $text-theme;
  padding: 125px 12px 20px 12px;
  width: 95%;
  margin: auto;
}


.simulation-page .bloc-first-theme {
  width: 30%;
  background-color: $first-theme-color;
  border-radius: 20px;
  padding: 5px;
  margin: 5px;
}

.simulation-page .bloc-border-round-first-theme {
  width: 30%;
  border: 2px solid $first-theme-color;
  border-radius: 20px;
  padding: 5px;
  margin: 5px;
}

.simulation-page .navigation-descendante li {
  line-height: 180%;
}

.progress-bar-vertical {
  width: 80px;
  min-height: 150px;
  display: flex;
  align-items: flex-end;
  margin: auto;
  margin-top: 30px;
  font-size: 3rem;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  background-color: #ad003f;
}

.homme {
  background-color: #A9A9A9 !important;
}

.ft-number {
  font-size: 26px !important;
  font-weight: bold !important;
}

.ft-txt-ope {
  font-size: 18px !important;
  font-weight: normal !important;
  color: black !important;
}

.ft-b-txt26-ope {
  font-size: 26px !important;
  font-weight: bold !important;
}

.width-150-ope {
  width: 150px !important;
}

.height-150-ope {
  height: 150px !important;
}

.bloc-simu {
  border-radius: 10px;
}

.block-concretisation {
  .block-liens {
    height: 270px;
    text-align: center;
    box-shadow: 0 0 10px $grey-500;
    border-radius: 5px;

    &:hover {
      text-decoration: none;
    }
    img {
      width: 30%;
    }
    .separator-center {
      display: block;
      margin: 0 auto;
      border-bottom: 1px solid $first-theme-color;
      width: 40%;
    }
  }
}

  @media (min-width: 768px) {

    .simulation-page .calculette {
      width: 80%;
      padding: 5px;
      margin: auto;
    }

    .simulation-page .accroche {
      font-size: 3rem;
    }

    .simulation-page .accroche .text-large {
      font-size: 3.5rem;
    }

    .simulation-page .nav .nav-item {
      width: 20%;
    }

    .simulation-page .know-more iframe {
      height: 360px;
    }

    .simulation-page .know-more .col-links {
      margin-top: 0;
    }

    .simulation-page .bloc-content-text .img-content-text {
      box-shadow: 0 0 1rem $gris-middle;
      margin-left: 20px;
      width: 320px;
    }
  }

  @media (min-width: 992px) {

    .simulation-page .image-step-bloc{
      height: 135px;
    }

    .simulation-page .image-step-bloc img {
      max-height: 135px;
    }

    .block-concretisation {
      .block-liens {
        transition: 0.5s;
      }

      .block-liens:hover {
        box-shadow: 0 0 15px $grey-700;

      }
    }
  }
