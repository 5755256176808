.carousel-top .carousel {
  border-bottom: 5px solid $third-theme-color;
}

.carousel-top .carousel-item {
  text-align: center;
  color: $default-white;
}

.carousel-top .carousel-item-bloc .separator {
  -webkit-mask-position: center;
  mask-position: center;
}

.carousel-top .carousel-item-bloc {
  margin-bottom: 15px;
}

.carousel-top .carousel-item .row {
  height: 600px;
}

.carousel-title {
  font-size: 3.5rem;
}

.carousel-top .carousel .separator {
  margin: 0 auto 40px auto;
  max-width: 30%;
}

.carousel-subtitle {
  font-size: 1.6rem;
  margin: 20px 0 28px 0;
}

.carousel-text {
  margin-top: 15px;
  font-size: 1.2rem;
}

.carousel .bulle-white {
  float: left;
  width: 80px;
  height: 80px;
  background-color: $default-white;
  border-radius: 50px;
  margin-right: 20px;
}

.carousel .bulle-white img {
  width: 60%;
  display: block;
  padding-top: 17%;
  margin: auto;

}

.carousel-item-bloc .button-0, .carousel-item-bloc .button-1 {
  border-radius: 30px;
  padding: 6px 35px;
  font-size: 1.6rem;
  margin-bottom: 20px;
  margin-top: 50px;
}

.pret-immo {
  background-color: $first-theme-color;
}
.pret-immo .button-0 {
  color: $default-white;
  background-color: $third-theme-color;
}
.pret-immo .button-1 {
  color: $third-theme-color;
  background-color: $default-white;
}
.pret-immo .button-0:hover {
  color: $default-white;
  background-color: $third-theme-color-darker;
  text-decoration: none;
}
.pret-immo .button-1:hover {
  color: $third-theme-color;
  background-color: $default-white;
  text-decoration: none;
}

.assurance-emprunteur {
  background-color: $gris-carousel;
}
.assurance-emprunteur .button-0 {
  color: $default-white;
  background-color: $sixth-theme-color;
}
.assurance-emprunteur .button-1 {
  color: $sixth-theme-color;
  background-color: $default-white;
}

.assurance-emprunteur .button-0:hover {
  color: $default-white;
  background-color: $sixth-theme-color-darker;
  text-decoration: none;
}
.assurance-emprunteur .button-1:hover {
  color: $sixth-theme-color;
  background-color: $default-white;
  text-decoration: none;
}

.assurance-habitation {
  background-color: $sixth-theme-color;
}
.assurance-habitation .button-0 {
  background-color: $first-theme-color;
  color: $default-white;
}
.assurance-habitation .button-1 {
  color: $first-theme-color;
  background-color: $default-white;
}

.assurance-habitation .button-0:hover {
  background-color: $first-theme-color-darker;
  color: $default-white;
  text-decoration: none;
}
.assurance-habitation .button-1:hover {
  color: $first-theme-color;
  background-color: $second-theme-color;
  text-decoration: none;
}

.button-first {
  background-color: $first-theme-color;
  color: $default-white;
  border-radius: 20px;
  padding: 7px 18px;
}
.button-first:hover {
  color: $default-white;
  background-color: $first-theme-color-darker;
  text-decoration: none;
}

.carousel-image-bloc {
  width: 75%;
  text-align: center;
  position: relative;
  height: 200px;
}
.carousel-image-bloc img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
}

#carouselImmo svg.fa-chevron-left, #carouselImmo svg.fa-chevron-right {
  font-size: 3rem;
  color: $first-theme-color;
}

@media (min-width: 768px) {
  .carousel-top .carousel-item-bloc {
    padding-left: 10%;
    text-align: left;
  }

  .carousel-top .carousel-item .row {
    height: 300px;
  }

  .carousel-top .carousel-item-bloc .separator {
    -webkit-mask-position: left;
    mask-position: left;
    margin-left: 0;
  }

  .carousel-top .carousel-title {
    font-size: 2.5rem;
  }

  .carousel-top .carousel-subtitle {
    font-size: 2.2rem;
  }

  .carousel-item-bloc .button-0, .carousel-item-bloc .button-1 {
    font-size: 1.8rem;
  }

  .carousel-image-bloc {
    width: 90%;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .carousel-image-bloc img {
    width: 75%;
  }

  .carousel-top .carousel-title {
    font-size: 3.5rem;
  }

  .carousel-top .carousel-subtitle {
    font-size: 2.6rem;
  }

  .carousel-control-prev, .carousel-control-next {
    width: 6%;
  }

  .carousel-item-bloc .button-0, .carousel-item-bloc .button-1 {
    font-size: 2rem;
  }

}
