.form-control {
  border-radius: 20px;
  border: 2px solid $first-theme-color;
}

.tab {
  display: none;
}

form .step {
  opacity: 1;
}

form .step.active {
  opacity: 1;
  background-color: $first-theme-color;
}

.simulation-page .bloc-form .step {
  padding: 2px 18px;
}

.simulation-page .bloc-form .titre-form {
  font-size: 20px;
  //font-weight: bold;
  color:$first-theme-color;
  margin-bottom:20px;
}

.spinner-border {
  display: none;
}

.alerte-mail .alert {
  display: none;
}

/**
 * Select
 */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown select.form-control {
  color: $text-theme;
  font-size: inherit;
  padding-right: 2.5em;
  border: 2px solid $first-theme-color;
  margin: 0;
  border-radius: 20px;
  text-indent: 10px;
  line-height: initial;
  text-overflow: '';
  -moz-appearance: none;
  -webkit-appearance: none;
}

.custom-dropdown select.form-control:focus {
  box-shadow: none;
  border: 2px solid $sixth-theme-color;
}

.custom-dropdown::before,
.custom-dropdown::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.custom-dropdown::after {
  content: "\2228" !important;
  font-weight: bold;
  height: 1em;
  font-size: 1.4rem;
  line-height: 1;
  right: 1.2em;
  top: 50%;
  margin-top: -.5em;
  color: $first-theme-color;
}

.custom-dropdown::before {
  width: 2em;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 15px 15px 0;
}

.custom-dropdown.pink::before {
  background-color: $second-theme-color;
}

.custom-dropdown.pink::after {
  color: $first-theme-color;
}

.custom-dropdown select.form-control.pink {
  background-color: $second-theme-color;
}

.custom-dropdown.blue::after {
  color: $third-theme-color;
}

.custom-dropdown.blue select.form-control {
  background-color: $default-white;
  border: 2px solid $fourth-theme-color;
}

/**
 * input
 */
.form-group label {
  color: $second-theme-color;
  margin-right: 0;
  padding-right: 0;
}

.form-search input::placeholder, .form-search select::placeholder  {
  text-align: center;
}

select option {
  text-align: center;
}

/**
 * Range
 */
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 12.8px 5px 12.8px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(13, 13, 13, 0.05);
  background: $second-theme-color;
  border-radius: 1.3px;
  border: 0.2px solid rgba(1, 1, 1, 0);
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 3px $default-dark, 0px 0px 3px $default-dark;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  background: $first-theme-color;
  border: 5px solid $default-white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: $default-white;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(13, 13, 13, 0.05);
  background: $second-theme-color;
  border-radius: 1.3px;
  border: 0.2px solid rgba(1, 1, 1, 0);
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 3px $default-dark, 0px 0px 3px $default-dark;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  background: $first-theme-color;
  border: 5px solid $default-white;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $second-theme-color;
  border: 0.2px solid rgba(1, 1, 1, 0);
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(13, 13, 13, 0.05);
}
input[type=range]::-ms-fill-upper {
  background: $second-theme-color;
  border: 0.2px solid rgba(1, 1, 1, 0);
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(13, 13, 13, 0.05);
}
input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 3px $default-dark, 0px 0px 3px $default-dark;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  background: $first-theme-color;
  border: 5px solid $default-white;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: $fourth-theme-color;
}
input[type=range]:focus::-ms-fill-upper {
  background: $fourth-theme-color;
}

#nb_pieces_input {
  margin: 7px 0;
  text-align: center;
}

.home-recherche-form-form input {
  font-size: 1.6rem;
  color:$text-theme;
}
.home-recherche-form-form input:focus {
  border: 2px solid $sixth-theme-color;
  box-shadow: none;
}

.content-form input[type="text"].has-float-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 110% !important;
  top: 0.7em !important;
}

.content-form input[type="text"].slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  padding-top: 10px !important;
}

.content-form input[type="text"].slider-tick.in-selection,.slider-selection.tick-slider-selection,.slider-handle{
  background-image: linear-gradient(to bottom, $first-theme-color, $first-theme-color) !important;
}

.content-form input[type="text"].has-float-label label::after, .has-float-label > span::after{
  background: $default-white !important;
}

.content-form input[type="text"].add-valeur-residuelle{
  opacity: 1;
  height: 80px;
}

.content-form input[type="text"].hide-valeur-residuelle{
  opacity: 0;
  height: 0px;
}


/**
 * submit
 */
input[type="submit"], button.btn-valid-form {
  border-radius: 5px;
  font-size: 1.4rem;
  background-color: $first-theme-color;
  color: $default-white;
}
input[type="submit"]:hover, button.btn-valid-form:hover {
  border-radius: 5px;
  font-size: 1.4rem;
  background-color: $third-theme-color-darker;
  color: $default-white;
}
input[type="submit"].green, button.btn-valid-form.green {
  background-color: $sixth-theme-color;
  color: $default-white;
}
input[type="submit"].green:hover, button.btn-valid-form.green:hover {
  background-color: $sixth-theme-color-darker;
  color: $default-white;
}

[type="radio"]:checked,
[type="radio"]:not(:checked),
[type="checkbox"]:checked:not(.rgpd),
[type="checkbox"]:not(:checked):not(.rgpd) {
  position: absolute;
  left: -9999px;
}

button {
  font-size: 1.4rem;
}

.form-immo {
  min-height: 100px;
}

.content-form .form-immo .form-immo-check {
  min-height: 40px
}

.form-immo .form-radio .form-check {
  width: 100%;
  text-align: center;
  padding: 0;
}

.img-form {
  max-width: 75%;
  filter: grayscale(1);
  position: relative;
}

.img-form.active {
  filter: grayscale(0);
}
.content-form .form-check [type="checkbox"] + label:not(.rgpd-label) {
  width: 100%;
  cursor: pointer;
  background-color: $second-theme-color;
  color: $first-theme-color;
  padding: 3px 11px 3px 11px;
  border-radius: 25px;
}
.content-form .form-check [type="checkbox"]:checked + label:not(.rgpd-label) {
  background-color: $first-theme-color;
  color: $second-theme-color;
}

.content-form .form-check [type="checkbox"] + label:before:not(.rgpd-label) {
  font-weight: 900;
  color: $first-theme-color;
  margin-right: 11px;
}

.content-form .form-check [type="checkbox"]:checked + label:before:not(.rgpd-label) {
  font-weight: 900;
  color: $second-theme-color;
}
.content-form .form-check [type="checkbox"] + label.rgpd-label {
  //width: 100%;
  cursor: pointer;
  padding: 3px 11px 3px 11px;
}
.content-form .form-check [type="checkbox"]:checked + label.rgpd-label {
}

.content-form .form-check [type="checkbox"] + label.rgpd-label:before {
  font-weight: 900;
  margin-right: 11px;
}

.content-form .form-check [type="checkbox"]:checked + label.rgpd-label:before {
  font-weight: 900;
}

.content-form .form-check .form-control.rgpd {
  width: 10%;
  position: relative;
  float: left;
  margin-top: 20px;
}

.content-form .form-check label.rgpd-label {
  width: 90%;
  float: left;
}

.content-form .form-radio-buttons [type="radio"] + label,
.content-form .form-radio-buttons [type="radio"] + label.error + label{
  display: block;
  position: relative;
  background-color: $default-white;
  color: $first-theme-color;
  font-size: 1.8rem;
}

.content-form .form-radio-buttons [type="radio"]:checked + label.form-check-label,
.content-form .form-radio-buttons [type="radio"]:checked + label.error + label.form-check-label {
  color: $first-theme-color;
  background-color: $default-white;
  font-weight: bold;
}

.content-form .form-radio-buttons [type="radio"] + label.form-check-label::before
.content-form .form-radio-buttons [type="radio"] + label.error + label.form-check-label::before {
  content: '';
}

.content-form .form-radio-buttons [type="radio"] + label.form-check-label:hover,
.content-form .form-radio-buttons [type="radio"]:focus + label.form-check-label,
.content-form .form-radio-buttons [type="radio"] + label.error + label.form-check-label:hover,
.content-form .form-radio-buttons [type="radio"]:focus + label.error + label.form-check-label {
  font-weight: bold;
  transition: 0.5s;
}

.content-form .form-radio-buttons [type="radio"]:checked + label.form-check-label,
.content-form .form-radio-buttons [type="radio"]:checked + label.error + label.form-check-label {
  color: $first-theme-color;
  font-size: 2rem;
}

.content-form .form-check input[type="radio"] + label.error {
  color: $first-theme-color;
  position: absolute;
  top: 19px;
  left: 0;
  width: 200px;
}

.content-form .form-radio-buttons .form-check input[type="radio"] + label.error {
  color: $first-theme-color;
  position: absolute;
  top: 40px;
  left: -15px;
  width: 200px;
}

.content-form .row .bloc-form {
  // box-shadow: 0 0  0.5rem black;
  //background-color: $fifth-theme-color;
  padding: 10px;
  font-size: 1.6rem;
}

.content-form .row .form-control {
  box-shadow: none;
  height: auto;
}

.content-form .form-control {
  //background-color: $fifth-theme-color;
  border: none;
  border-radius: 0;
  font-size: 1.4rem;
}

.content-form input[type=text],
.content-form input[type=email],
.content-form input[type=tel],
.content-form input[type=number],
.content-form .select-form .form-group select,
.content-form textarea.form-control {
  border: none;
  border-bottom: solid 1px $second-theme-color;
  border-radius: 0;
  font-size: 2rem;
}

.content-form input[type=text]:focus,
.content-form input[type=email]:focus,
.content-form input[type=number]:focus,
.content-form input[type=tel]:focus,
.content-form textarea.form-control:focus {
  border-bottom: solid 1px $first-theme-color;
  box-shadow: none;
}

.content-form input[type=text].is-invalid,
.content-form input[type=email].is-invalid,
.content-form input[type=tel].is-invalid,
.content-form input[type=number].is-invalid,
.content-form textarea.form-control.is-invalid {
  border-bottom: solid 1px $first-theme-color;
  background-color: $second-theme-color;
}

.content-form .form-control select {
  border: none;
  border-bottom: solid 1px $second-theme-color;
  border-radius: 0;
  //background-color: $fifth-theme-color;
  font-size: 1.6em;
}

.content-form .form-control select:focus {
  border-bottom: solid 1px $first-theme-color;
}

.content-form .btn-valid-form,
.content-form .btn-valid-form:hover
{
  font-size: 1.8rem;
}

/*
.content-form .form-group .form-check-label {
  color: $text-theme;
  margin-left: 5px;
}*/
.content-form .form-group {
  color: $text-theme;
}

.content-form .form-user label.label_range,
.content-form .form-user span.range-slider__value {
  font-size: 1.8rem;
}

.content-form .form-user label.label_range {
  color: $text-theme;
}

/*
.content-form label.dirty,
.col-form-label.dirty {
  color: $text-theme;
}*/

.content-form legend,
.content-form .form-group label {
  text-align: center;
  background-color: $first-theme-color;
  color: $default-white;
  border-radius: 10px;
  font-size: 1.8rem;
}
.content-form .form-group label.rgpd-label {
  text-align: left;
  background-color: $default-white;
  color: $text-theme;
  border-radius: 10px;
  font-size: 1.8rem;
}

.content-form .form-user label {
  background-color: $default-white;
  color: $default-white;
}

.content-form .form-user label.error,
.content-form .form-group label.error{
  color: $first-theme-color;
  background-color: $default-white;
  font-size: 1.4rem;
}


.content-form .form-user label.dirty {
  background-color: $default-white;
  color: $text-theme;
}

.form-immo .form-check  {
  float: left;
  margin: auto;
  margin-bottom: 20px;
}

.content-form .form-surface::after {
  content: "m²";
  position: absolute;
  top: 5px;
  right: 0px;
  font-size: 1.8rem;
  color: $text-theme;
}

.content-form .form-mensualite::after {
  content: "€ / mois";
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 1.8rem;
  color: $text-theme;
}

.content-form .form-montant::after {
  content: "€";
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 1.8rem;
  color: $text-theme;
}

.content-form .form-poucentage::after {
  content: "%";
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 1.8rem;
  color: $text-theme;
}

.content-form .form-mensuel::after {
  content: "mois";
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 1.8rem;
  color: $text-theme;
}

.content-form .form-annee::after {
  content: "ans";
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 1.8rem;
  color: $text-theme;
}

.content-form .invalid {
  background-color: $second-theme-color;
}

form.formulaire-step .step-1 .form-immo {
  display: none;
}

form.formulaire-step .step-1 .form-immo:first-of-type {
  display: flex;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: $sixth-theme-color;
}


/*
Date picker
 */
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 24rem;
}

.bootstrap-datetimepicker-widget table td span.active,
.bootstrap-datetimepicker-widget table td.active {
  background-color: $third-theme-color;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
.bootstrap-datetimepicker-widget table td span:hover {
  background-color: $fourth-theme-color;
}
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td.day {
  height: 50px;
  width: 50px;
  line-height: 50px;
}


.simulation-page .bloc-content-text .content-form h2 {
  color: $default-dark;
}

.upload-area {
  width: 70%;
  height: 200px;
  border: 2px solid lightgray;
  border-radius: 3px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  overflow: auto;
}

.upload-area:hover{
  cursor: pointer;
}

.upload-area p {
  text-align: center;
  font-weight: normal;
  font-family: sans-serif;
  line-height: 50px;
  color: darkslategray;
}

#file {
  display: none;
}

/* Thumbnail */
.thumbnail {
  width: 80px;
  height: 80px;
  padding: 2px;
  border: 2px solid lightgray;
  border-radius: 3px;
  float: left;
}

.text-form-rac {
  color: $first-theme-color;
  font-weight: bold;
}

@media (min-width: 576px) {
  .text-form-rac {
    color: $text-theme;
    font-weight: normal;
  }
}

@media (min-width: 768px) {
  .content-form .col-sm-6,
  .content-form .col-sm-4 {
    min-height: 110px;
  }

  .content-form .form-immo .form-immo-check {
    min-height: 40px
  }

  .content-form .form-radio-buttons [type="radio"] + label,
  .content-form .form-radio-buttons [type="radio"] + label.error + label {
    font-size: 2.5rem;
  }

  .content-form .form-radio-buttons [type="radio"] + label.error {
    font-size: 1.4rem;
  }

  .form-content .form-surface:after {
    right: 27px;
    top: 6px;
  }


  .content-form .form-surface::after {
    content: "m²";
    position: absolute;
    top: 5px;
    right: 30px;
    font-size: 1.8rem;
    color: $text-theme;
  }

  .content-form .form-mensualite::after {
    content: "€ / mois";
    position: absolute;
    top: 6px;
    right: 30px;
    font-size: 1.8rem;
    color: $text-theme;
  }

  .content-form .form-montant::after {
    content: "€";
    position: absolute;
    top: 6px;
    right: 30px;
    font-size: 1.8rem;
    color: $text-theme;
  }

  .content-form .form-poucentage::after {
    content: "%";
    position: absolute;
    top: 6px;
    right: 30px;
    font-size: 1.8rem;
    color: $text-theme;
  }

  .simulation-page .bloc-form .step {
    padding: 5px 18px;
  }

  .content-form .form-radio-buttons [type="radio"]:checked + label.form-check-label,
  .content-form .form-radio-buttons [type="radio"]:checked + label.error + label.form-check-label {
    font-size: 3rem;
  }
}
