.home-recherche-title {
  color: $first-theme-color;
  font-size: 2.2rem;
}

.home-recherche .separator {
  width: 50%;
}

.home-recherche-bloc {
  color: $first-theme-color;
  font-size: 1.4rem;
}

.home-recherche-type {
  z-index: 10;
  height: 150px;
  position: relative;
  font-weight: 700;
}

.home-recherche-type img {
  height: 120px;
}

.home-recherche-type a:hover {
  color: $first-theme-color;
  text-decoration: none;
}

.home-recherche-form-nb-annonces {
  border-radius: 30px 30px 0 0;
}
.home-recherche-form-nb-annonces svg {
  font-size: xx-large;
}

.home-recherche-form-nb-annonces p {
  margin-top: 10px;
  font-size: 1.8rem;
}

.nb-annonces {
  font-size: xx-large;
  font-weight: 700;
}

.home-recherche-form-form {
  border-radius: 0 0 30px 30px;
}

@media (min-width: 768px) {
  .home-recherche-bloc .background-pink {
    width: 60%;
    height: 52%;
    top: 12.5%;
    left: 16.5%;
  }

  .home-recherche-form-nb-annonces  {
    font-size: 1.6rem;
  }

  .home-recherche-form-nb-annonces .nb-annonces, .home-recherche-form-nb-annonces .fa.fa-plus-circle {
    font-size: xx-large;
  }
}