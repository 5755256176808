/**
 * Footer
 */

footer h5 {
  font-size: 1.6rem;
}
footer {
  font-size: 1.4rem;
}
img {
  max-width: 100%;
}

.links-footer {
  text-align: center;
}
.links-footer a {
  width: 100%;
}
.label-finance:hover {
  text-decoration: none;
}
.label-par {
  display: block;
  vertical-align: middle;
}
.label-finance img {
  display: block;
  max-width: 60%;
}

/**
 * Avis
 */

.img-avis {
  max-height: 40px;
}

.text-avis {
  box-shadow: 0 0 2em $gris-middle;
  border-radius: 5px;
  background-color: white;
  padding: 0 7px;
}
.nb-avis {
  font-size: 1.6rem;
  font-weight: 800;
}
.savoir-plus {
  color: $third-theme-color;
  font-size: 1.2rem;
  text-decoration: underline;
}


/**
 * Bloc mentions
 */

.mentions-footer {
  font-size: 1.4rem;
}

.mentions-footer .copyright {
  text-align: left;
}

.mentions-footer .mentions {
  text-align: center;
}

.follow {
  font-size: 1.6rem;
}
.follow svg {
  font-size: x-large;
}

/**
 * Bloc vidéo
 */

.footer-bloc-video {
  margin-top: -20px;
  padding-bottom: 20px;
}

.footer-bloc-video .embed-responsive {
  box-shadow: 10px -10px 0px rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
}

.footer-bloc-video a {
  font-size: 1.2rem;
}



@media (min-width: 576px) {
  .mentions-footer .mentions {
    text-align: right;
    font-size: 1.4rem;
  }

  .follow {
    font-size: 1.8rem;
  }

  .footer-bloc-video {
    margin-top: 0;
    padding-bottom: 0;
  }

  .footer-bloc-video .row {
    width: 90%;
    margin: auto;
  }

  .footer-bloc-video .footer-text {
    padding-top: 15px;
  }

  .footer-bloc-video .embed-responsive {
    margin-top: -40px;
    border: 10px solid $default-white;
  }


  .footer-bloc-video a {
    font-size: 1.6rem;
  }

  .label-finance img {
    max-width: 45%;
  }

}