.pagination .page-item .page-link {
  background-color: $default-white;
  border: 1px solid $grey-300;
}
.pagination .page-item a.page-link:hover {
  background-color: $first-theme-color;
  color: $default-white;
  border-color: $first-theme-color-darker;
}

.pagination .page-item.active .page-link {
  background-color: $first-theme-color-darker;
  border-color: $first-theme-color-darker;
}

.pagination .page-item.disabled .page-link {
  color: $first-theme-color;
  background-color: $default-white;
  border: 1px solid $grey-300;
}