html {
  font-size: 62.5%; // équilibrage du rem face au pixel : 1rem = 10px

}
body {
  font-family: 'MyriadPro'  !important;
  font-size: 1.6rem;
}

@font-face {
  font-family: 'MyriadPro';
  src: url($fonts-path + 'MyriadPro/MyriadPro-Regular.eot');
  src: url($fonts-path + 'MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
  url($fonts-path + 'MyriadPro/MyriadPro-Regular.woff') format('woff'),
  url($fonts-path + 'MyriadPro/MyriadPro-Regular.ttf')  format('truetype'),
  url($fonts-path + 'MyriadPro/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro-It';
  src: url($fonts-path + 'MyriadPro/MyriadPro-It.eot');
  src: url($fonts-path + 'MyriadPro/MyriadPro-It.eot?#iefix') format('embedded-opentype'),
  url($fonts-path + 'MyriadPro/MyriadPro-It.woff') format('woff'),
  url($fonts-path + 'MyriadPro/MyriadPro-It.ttf')  format('truetype'),
  url($fonts-path + 'MyriadPro/MyriadPro-It.svg#MyriadPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadPro';
  src: url($fonts-path + 'MyriadPro/MyriadPro-Bold.eot');
  src: url($fonts-path + 'MyriadPro/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
  url($fonts-path + 'MyriadPro/MyriadPro-Bold.woff') format('woff'),
  url($fonts-path + 'MyriadPro/MyriadPro-Bold.ttf')  format('truetype'),
  url($fonts-path + 'MyriadPro/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url($fonts-path + 'Comfortaa/Comfortaa_Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
