.btn-omi {
  width: 300px;
  background-color: $first-theme-color;
  color: $gris;
  @media (max-width: 400px) {
    width: 100%;
  }
}
.btn-omi:hover {
  background-color: $second-theme-color;
  color: $gris;
}

.ul-omi-typo-biens {
  line-height: 4em;
  li{
    list-style-type: none;
    font-weight: bold;
    span{
      color: $first-theme-color;
    }
  }
}

.ul-ope-age-moyen {
  margin-top: 15px;
  line-height: 2em;
  li{
    list-style-type: none;
    font-weight: normal;
    span{
      color: $first-theme-color;
      font-weight: bold;
      font-size: 22px;
    }
  }
}

.ul-omi-acquereur-img-title {
  line-height: 2em;
  li{
    list-style-type: none;
    font-weight: bold;
    span{
      color: $first-theme-color;
    }
  }
}

.ul-omi-acquereur-body {
  line-height: 2em;
  text-align: center;
  float: left;
  clear: both;
  width: 100%;
  li{
    list-style-type: none;
    font-weight: bold;
    span{
      color: $first-theme-color;
    }
  }
}

#table-taux-credit-immo th{
  background-color: #ad003f;
  vertical-align: middle;
  text-align: center;
  color: #FFFFFF;
}
