// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Greyscale Colors
// + @Bootstrap Color System
// + @Default Colors
// + @Inverted Colors
// + @Others
// + @Header Themes
// + @Social Networks Colors

// ---------------------------------------------------------
// @Greyscale Colors
// ---------------------------------------------------------

// Colors below are ordered from lightest to darkest

$grey-100             : #f9fafb;
$grey-200             : #f2f3f5;
$grey-300             : #e6eaf0;
$grey-400             : #d3d9e3;
$grey-500             : #b9c2d0;
$grey-600             : #7c8695;
$grey-700             : #72777a;
$grey-800             : #565a5c;
$grey-900             : #313435;

$grey-colors-alt: (
        grey-100 : #f9fafb,
        grey-200 : #f2f3f5,
        grey-300 : #e6eaf0,
        grey-400 : #d3d9e3,
        grey-500 : #b9c2d0,
        grey-600 : #7c8695,
        grey-700 : #72777a,
        grey-800 : #565a5c,
        grey-900 : #313435,
);


// ---------------------------------------------------------
// @LP Themes
// ---------------------------------------------------------

$text-theme: #8B8B8B;

$first-theme-color : #BF1342;
$second-theme-color : #F9EDF1;
$third-theme-color : #5C80A8;
$fourth-theme-color : #CED9E4;
$fifth-theme-color : #F2F2F2;
$sixth-theme-color : #2DB279;

$fond-acces-connexion: #4A4A49;
$acces-connexion: #A6A6A6;

$sixth-theme-color-lighter: #BEF5DD;

$first-theme-color-darker: #99113B;
$third-theme-color-darker: #4E7096;
$sixth-theme-color-darker: #238058;

$gris-carousel: #7a7a7a;
$grey-step-form : #b2b2b2;
$ombre-rose: #dca9b7;

$gris: #CCCCCC;
$gris-moyen: #b1b1b1;
$gris-middle: #bab9ba;
$gris-clair : #f9fafc;
$gris-sous-menu : #aaaaaa;
$gris-fonce: #333333;

// ---------------------------------------------------------
// @Bootstrap Color System
// ---------------------------------------------------------

$blue   : #2196f3;
$indigo : #3f51b5;
$purple :#9c27b0;
$pink   : #e91e63;
$red    : #f44336;
$orange : #ff9800;
$yellow : #ffeb3b;
$green  : #4caf50;
$teal   : #009688;
$cyan   : #00bcd4;

// ---------------------------------------------------------
// @Default Colors
// ---------------------------------------------------------

$default-danger       : #ff3c7e;
$default-dark         : #313435;
$default-grey         : #565a5c;
$default-info         : #0f9aee;
//$default-primary      : #7774e7;
$default-primary      : $first-theme-color;
$default-success      : #37c936;
$default-text-color   : #72777a;
$default-warning      : #fc0;
$default-white        : #fff;

// ---------------------------------------------------------
// @Inverted Colors
// ---------------------------------------------------------

$inverse-danger       : lighten($default-danger, 35%);
$inverse-info         : lighten($default-info, 45%);
$inverse-primary      : lighten($default-primary, 30%);
$inverse-success      : lighten($default-success, 45%);
$inverse-warning      : lighten($default-warning, 45%);


// ---------------------------------------------------------
// @Others
// ---------------------------------------------------------

$border-color         : #e6ecf5;
$collapsed-size       : 70px;
$header-height        : 65px;
$offscreen-size       : 280px;
$side-nav-dark        : #313644;
$side-nav-dark-border : rgba(120, 130, 140, 0.3);
$side-nav-dark-font   : #99abb4;

// ---------------------------------------------------------
// @Header Themes
// ---------------------------------------------------------

$theme-danger         : #f53f61;
$theme-dark           : lighten($side-nav-dark, 10%);
$theme-info           : $default-info;
$theme-primary        : $default-primary;
$theme-success        : desaturate($default-success, 5%);
