.clear {
  clear: both;
}

h2, h3.title {
  font-size: 3rem;
}

.separator {
  width: 100%;
  height: 5px;
  -webkit-mask-image: url($images-path + 'base/separator.svg');
  mask-image: url($images-path + 'base/separator.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.separator-left {
  -webkit-mask-position: left;
  mask-position: left;
}
.separator-center {
  -webkit-mask-position: center;
  mask-position: center;
}

.separator-white {
  background-color: $default-white;
}

.separator-grey {
  background-color: $gris;
}

.pastille {
  overflow: hidden;
  padding: 1px 17px;
  border-radius: 100px;
  font-size: 3.5rem;
  font-weight: 700;
  display: inline-block;
}

.pastille-global {
  overflow: hidden;
  padding: 1px 17px;
  border-radius: 100px;
  font-size: 20px;
  display: inline-block;
  width:60px;
  height:60px;
  line-height: 50px;
  background-color: $grey-step-form;
}

.pastille-2 {
  padding: 2px 8px;
}

.pastille-red{
  background-color: $first-theme-color;
}

.pastille-grey {
  background-color: $grey-step-form;
  text-shadow: 1px 0px 1px $gris-fonce,
  0px 1px 1px $gris-fonce,
  2px 1px 1px $gris-fonce,
  1px 2px 1px $gris-fonce,
  3px 2px 1px $gris-fonce,
  2px 3px 1px $gris-fonce,
  4px 3px 1px $gris-fonce,
  3px 4px 1px $gris-fonce,
  5px 4px 1px $gris-fonce,
  4px 5px 1px $gris-fonce,
  6px 5px 1px $gris-fonce,
  5px 6px 1px $gris-fonce,
  7px 6px 1px $gris-fonce,
  6px 7px 1px $gris-fonce,
  8px 7px 1px $gris-fonce,
  7px 8px 1px $gris-fonce,
  9px 8px 1px $gris-fonce,
  8px 9px 1px $gris-fonce,
  10px 9px 1px $gris-fonce,
  9px 10px 1px $gris-fonce,
  11px 10px 1px $gris-fonce,
  10px 11px 1px $gris-fonce,
  12px 11px 1px $gris-fonce,
  11px 12px 1px $gris-fonce,
  13px 12px 1px $gris-fonce,
  12px 13px 1px $gris-fonce,
  14px 13px 1px $gris-fonce,
  13px 14px 1px $gris-fonce,
  15px 14px 1px $gris-fonce,
  14px 15px 1px $gris-fonce,
  16px 15px 1px $gris-fonce,
  15px 16px 1px $gris-fonce,
  17px 16px 1px $gris-fonce,
  16px 17px 1px $gris-fonce,
  18px 17px 1px $gris-fonce,
  17px 18px 1px $gris-fonce,
  19px 18px 1px $gris-fonce,
  18px 19px 1px $gris-fonce,
  20px 19px 1px $gris-fonce,
  19px 20px 1px $gris-fonce,
  21px 20px 1px $gris-fonce;
}

.pastille-red {
  background-color: $first-theme-color;
  text-shadow: 1px 0px 1px $first-theme-color-darker,
  0px 1px 1px $first-theme-color-darker,
  2px 1px 1px $first-theme-color-darker,
  1px 2px 1px $first-theme-color-darker,
  3px 2px 1px $first-theme-color-darker,
  2px 3px 1px $first-theme-color-darker,
  4px 3px 1px $first-theme-color-darker,
  3px 4px 1px $first-theme-color-darker,
  5px 4px 1px $first-theme-color-darker,
  4px 5px 1px $first-theme-color-darker,
  6px 5px 1px $first-theme-color-darker,
  5px 6px 1px $first-theme-color-darker,
  7px 6px 1px $first-theme-color-darker,
  6px 7px 1px $first-theme-color-darker,
  8px 7px 1px $first-theme-color-darker,
  7px 8px 1px $first-theme-color-darker,
  9px 8px 1px $first-theme-color-darker,
  8px 9px 1px $first-theme-color-darker,
  10px 9px 1px $first-theme-color-darker,
  9px 10px 1px $first-theme-color-darker,
  11px 10px 1px $first-theme-color-darker,
  10px 11px 1px $first-theme-color-darker,
  12px 11px 1px $first-theme-color-darker,
  11px 12px 1px $first-theme-color-darker,
  13px 12px 1px $first-theme-color-darker,
  12px 13px 1px $first-theme-color-darker,
  14px 13px 1px $first-theme-color-darker,
  13px 14px 1px $first-theme-color-darker,
  15px 14px 1px $first-theme-color-darker,
  14px 15px 1px $first-theme-color-darker,
  16px 15px 1px $first-theme-color-darker,
  15px 16px 1px $first-theme-color-darker,
  17px 16px 1px $first-theme-color-darker,
  16px 17px 1px $first-theme-color-darker,
  18px 17px 1px $first-theme-color-darker,
  17px 18px 1px $first-theme-color-darker,
  19px 18px 1px $first-theme-color-darker,
  18px 19px 1px $first-theme-color-darker,
  20px 19px 1px $first-theme-color-darker,
  19px 20px 1px $first-theme-color-darker,
  21px 20px 1px $first-theme-color-darker;
}

.pastille.finish, .pastille-global.finish {
  background-color: $first-theme-color;
  //text-shadow: 1px 0px 1px $first-theme-color-darker,
  //0px 1px 1px $first-theme-color-darker,
  //2px 1px 1px $first-theme-color-darker,
  //1px 2px 1px $first-theme-color-darker,
  //3px 2px 1px $first-theme-color-darker,
  //2px 3px 1px $first-theme-color-darker,
  //4px 3px 1px $first-theme-color-darker,
  //3px 4px 1px $first-theme-color-darker,
  //5px 4px 1px $first-theme-color-darker,
  //4px 5px 1px $first-theme-color-darker,
  //6px 5px 1px $first-theme-color-darker,
  //5px 6px 1px $first-theme-color-darker,
  //7px 6px 1px $first-theme-color-darker,
  //6px 7px 1px $first-theme-color-darker,
  //8px 7px 1px $first-theme-color-darker,
  //7px 8px 1px $first-theme-color-darker,
  //9px 8px 1px $first-theme-color-darker,
  //8px 9px 1px $first-theme-color-darker,
  //10px 9px 1px $first-theme-color-darker,
  //9px 10px 1px $first-theme-color-darker,
  //11px 10px 1px $first-theme-color-darker,
  //10px 11px 1px $first-theme-color-darker,
  //12px 11px 1px $first-theme-color-darker,
  //11px 12px 1px $first-theme-color-darker,
  //13px 12px 1px $first-theme-color-darker,
  //12px 13px 1px $first-theme-color-darker,
  //14px 13px 1px $first-theme-color-darker,
  //13px 14px 1px $first-theme-color-darker,
  //15px 14px 1px $first-theme-color-darker,
  //14px 15px 1px $first-theme-color-darker,
  //16px 15px 1px $first-theme-color-darker,
  //15px 16px 1px $first-theme-color-darker,
  //17px 16px 1px $first-theme-color-darker,
  //16px 17px 1px $first-theme-color-darker,
  //18px 17px 1px $first-theme-color-darker,
  //17px 18px 1px $first-theme-color-darker,
  //19px 18px 1px $first-theme-color-darker,
  //18px 19px 1px $first-theme-color-darker,
  //20px 19px 1px $first-theme-color-darker,
  //19px 20px 1px $first-theme-color-darker,
  //21px 20px 1px $first-theme-color-darker;
}

.pastille-big-red {
  font-size: 2.5rem;
  padding: 32px 16px;
  background-color: $first-theme-color;
  text-shadow: 1px 0px 1px $first-theme-color-darker,
  0px 1px 1px $first-theme-color-darker,
  2px 1px 1px $first-theme-color-darker,
  1px 2px 1px $first-theme-color-darker,
  3px 2px 1px $first-theme-color-darker,
  2px 3px 1px $first-theme-color-darker,
  4px 3px 1px $first-theme-color-darker,
  3px 4px 1px $first-theme-color-darker,
  5px 4px 1px $first-theme-color-darker,
  4px 5px 1px $first-theme-color-darker,
  6px 5px 1px $first-theme-color-darker,
  5px 6px 1px $first-theme-color-darker,
  7px 6px 1px $first-theme-color-darker,
  6px 7px 1px $first-theme-color-darker,
  8px 7px 1px $first-theme-color-darker,
  7px 8px 1px $first-theme-color-darker,
  9px 8px 1px $first-theme-color-darker,
  8px 9px 1px $first-theme-color-darker,
  10px 9px 1px $first-theme-color-darker,
  9px 10px 1px $first-theme-color-darker,
  11px 10px 1px $first-theme-color-darker,
  10px 11px 1px $first-theme-color-darker,
  12px 11px 1px $first-theme-color-darker,
  11px 12px 1px $first-theme-color-darker,
  13px 12px 1px $first-theme-color-darker,
  12px 13px 1px $first-theme-color-darker,
  14px 13px 1px $first-theme-color-darker,
  13px 14px 1px $first-theme-color-darker,
  15px 14px 1px $first-theme-color-darker,
  14px 15px 1px $first-theme-color-darker,
  16px 15px 1px $first-theme-color-darker,
  15px 16px 1px $first-theme-color-darker,
  17px 16px 1px $first-theme-color-darker,
  16px 17px 1px $first-theme-color-darker,
  18px 17px 1px $first-theme-color-darker,
  17px 18px 1px $first-theme-color-darker,
  19px 18px 1px $first-theme-color-darker,
  18px 19px 1px $first-theme-color-darker,
  20px 19px 1px $first-theme-color-darker,
  19px 20px 1px $first-theme-color-darker,
  21px 20px 1px $first-theme-color-darker,
  20px 21px 1px $first-theme-color-darker,
  22px 21px 1px $first-theme-color-darker,
  21px 22px 1px $first-theme-color-darker,
  23px 22px 1px $first-theme-color-darker,
  22px 23px 1px $first-theme-color-darker,
  24px 23px 1px $first-theme-color-darker,
  23px 24px 1px $first-theme-color-darker,
  25px 24px 1px $first-theme-color-darker,
  24px 25px 1px $first-theme-color-darker,
  26px 25px 1px $first-theme-color-darker,
  25px 26px 1px $first-theme-color-darker,
  27px 26px 1px $first-theme-color-darker,
  26px 27px 1px $first-theme-color-darker,
  28px 27px 1px $first-theme-color-darker,
  27px 28px 1px $first-theme-color-darker,
  29px 28px 1px $first-theme-color-darker,
  28px 29px 1px $first-theme-color-darker,
  30px 29px 1px $first-theme-color-darker,
  29px 30px 1px $first-theme-color-darker,
  31px 30px 1px $first-theme-color-darker,
  30px 31px 1px $first-theme-color-darker,
  32px 31px 1px $first-theme-color-darker,
  31px 32px 1px $first-theme-color-darker,
  33px 32px 1px $first-theme-color-darker,
  32px 33px 1px $first-theme-color-darker,
  34px 33px 1px $first-theme-color-darker,
  33px 34px 1px $first-theme-color-darker,
  35px 34px 1px $first-theme-color-darker,
  34px 35px 1px $first-theme-color-darker;
}

.background-pink {
  width: 120px;
  height: 120px;
  left: 12.5%;
  background-color: $second-theme-color;
  border: solid 8px $default-white;
  border-radius: 60px;
  box-shadow: 0 0 1px $gris-moyen;
  position: absolute;
  z-index: 1;
}

.button-first {
  background-color: $first-theme-color;
  color: $default-white;
  border-radius: 20px;
  padding: 7px 18px;
}


.button-first-mini {
  background-color: $first-theme-color;
  color: $default-white;
  border-radius: 20px;
  padding: 1px 10px;
  font-size: 1.4rem;
}
.button-first:hover, .button-first-mini:hover {
  color: $default-white;
  background-color: $first-theme-color-darker;
  text-decoration: none;
}

.button-second {
  background-color: $second-theme-color;
  color: $first-theme-color;
  border-radius: 20px;
  padding: 7px 18px;
}
.button-second:hover {
  background-color: $first-theme-color;
  color: $second-theme-color;
  text-decoration: none;
}

.button-third {
  background-color: $fourth-theme-color;
  color: $third-theme-color;
  border-radius: 20px;
  padding: 7px 18px;
}
.button-third:hover {
  background-color: $third-theme-color;
  color: $fourth-theme-color;
  text-decoration: none;
}

.button-fourth {
  background-color: $third-theme-color;
  color: $default-white;
  border-radius: 20px;
  padding: 7px 18px;
}

.button-fourth-mini {
  background-color: $third-theme-color;
  color: $fourth-theme-color;
  border-radius: 20px;
  padding: 1px 10px;
}

.button-fourth:hover, .button-fourth-mini:hover {
  background-color: $third-theme-color-darker;
  color: $default-white;
  text-decoration: none;
}

.button-sixth {
  background-color: $sixth-theme-color;
  color: $default-white;
  border-radius: 20px;
  padding: 7px 18px;
}

.button-sixth:hover {
  background-color: $sixth-theme-color;
  color: $default-white;
  text-decoration: none;
}

.button-white {
  background-color: $default-white;
  color: $text-theme;
  border-radius: 20px;
  padding: 7px 18px;
}

.button-white:hover {
  background-color: $gris;
  color: $text-theme;
}

.button-white-mini {
  background-color: $default-white;
  color: $text-theme;
  border-radius: 20px;
  padding: 1px 10px;
}

.button-white-mini:hover {
  background-color: $gris;
  color: $text-theme;
}

.button-green-mini {
  background-color: $sixth-theme-color;
  color: white;
  border-radius: 20px;
  padding: 1px 10px;
}

.button-green-mini:hover {
  background-color: $sixth-theme-color-darker;
  color: white;
}

.ombre:after, .ombre:before {
  content: " ";
  position: absolute;
  width: 50%;
  height: 100px;
  z-index: 0;
}

.ombre:before {
  top: 10px;
  left: 8px;
  width: 50%;
  height: 130px;
  background: red;

  box-shadow: -7px 0 10px #555;
  transform: rotate(-5deg);
}
.ombre:after {
  background: red;
}

.crop-text-1 {
  -webkit-line-clamp: 1;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.crop-text-2 {
  -webkit-line-clamp: 2;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.crop-text-3 {
  -webkit-line-clamp: 3;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.crop-text-4 {
  -webkit-line-clamp: 4;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.wave {
  width: 100%;
  -webkit-mask-image: url($images-path + 'base/wave.svg');
  mask-image: url($images-path + 'base/wave.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.v-align-mid{
  vertical-align: middle;
}
.v-align-top{
  vertical-align: top !important;
}

.border-first-theme-color {
  border-color: $first-theme-color;
}

.border-second-theme-color {
  border-color: $second-theme-color;
}

.border-third-theme-color {
  border-color: $third-theme-color;
}

.border-fourth-theme-color {
  border-color: $fourth-theme-color;
}

.border-fifth-theme-color {
  border-color: $fifth-theme-color;
}

.border-sixth-theme-color {
  border-color: $sixth-theme-color;
}

.pastille-2-lignes {
  height: 50px;
}
@media (min-width: 768px) {
  .pastille, .pastille-global {
    padding: 2px 12px;
  }

  .pastille-2 {
    padding: 2px 9px;
  }

  .pastille-big-red {
    padding: 26px 16px;
  }

  .pastille-2-lignes {
    height: 60px;
  }

  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
}


@media (min-width: 992px) {
  .wave {
    height: 33px;
    margin-bottom: -3px;
  }

  .pastille-2 {
    padding: 2px 9px;
  }
}

@media (min-width: 1200px) {
  .wave {
    height: 39px;
  }
}
