.home-navigation-img {
  z-index: 10;
  position: relative;
  font-weight: 700;
  height: 120px;
  text-align: center;
}

.bloc-navigation .background-pink img{
  width: 60px;
}

.bloc-navigation {
  margin-bottom: 30px;
}

.bloc-navigation .background-pink {
  width: 60px;
  height: 60px;
}

.bloc-navigation span {
  font-weight: bold;
}

.bloc-keywords a {
  font-size: 1.4rem;
}

.bloc-navigation span {
  font-size: 1.6rem;
}

@media (min-width: 768px) {
  .home-navigation p {
    font-size: 1.6rem;
  }
  .bloc-navigation span {
    font-size: 1.8rem;
  }

  .bloc-navigation .background-pink {
    width: 100px;
    height: 100px;
    left: 21.5%;
    top: -15%;
  }
}

@media (min-width: 992px) {
  .home-navigation {
    width: 95%;
    font-size: 1.6rem;
  }

  .bloc-navigation .background-pink img{
    width: 90px;
    margin-left: 17px;
  }

  .bloc-navigation .background-pink img.too-big{
    width: 70px;
  }

  .bloc-navigation .background-pink img.too-high {
    height: 70px;
  }

  .bloc-navigation .background-pink {
    width: 120px;
    height: 120px;
  }

  .bloc-navigation span {
    font-size: 2.5rem;
  }
}