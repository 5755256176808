.simulation-page .list-annonces .title-annonce {
  font-size: 2.2rem;
}

.simulation-page .list-annonces .prix {
  font-size: 2.2rem;
}

.simulation-page .list-annonces .mensualite {
  font-size: 1.4rem;
}

.simulation-page .list-annonces .link-annonce {
  font-size: 1.3rem;
}

.simulation-page .list-annonces .bloc-image {
  text-align: center;
}

.simulation-page .list-annonces .bloc-image .fa-camera {
  font-size: 3.5rem;
}

.simulation-page .list-annonces .bloc-image .image-list-annonce {
  max-height: 200px;
  width: auto;
}

.simulation-page .list-annonces .text-photo-annonce svg, .simulation-page .list-annonces .text-photo-annonce .fa {
  font-size: 3.5rem;
  position: absolute;
  width: 30px;
  text-align: center;
  bottom: 20px;
  left: 18px;
}

.simulation-page .list-annonces .text-photo-annonce .nb-photos {
  position: absolute;
  width: 30px;
  text-align: center;
  bottom: 24px;
  left: 18px;
}

.simulation-page .list-annonces .item-annonce {
  min-height: 540px;
}

.simulation-page .list-annonces .item-annonce .simulation-liens {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 1.6em;
}

.simulation-page .container-tuiles, .simulation-page .container-tuiles .row {
  min-height: 400px;
}

.simulation-page .tuiles::before {
  display: table;
  content: " ";
}

.simulation-page .tuile {
  display: inline-block;
  width: 19%;
  height: 410px;
  text-align: center;
  position: relative;
  vertical-align: top;
  margin-bottom: 20px;
}

.simulation-page .tuile .tuile-bas,
.simulation-page .tuile .tuile-haut {
  position: relative;
  height: 100%;
  border-radius: 5px;
}

.simulation-page .tuile .tuile-haut {
  top: 0;
  margin-bottom: 60px;
}

.simulation-page .tuile .tuile-bas {
  bottom: 0;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.simulation-page .tuile img {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;

}

.simulation-page .tuile .titre-tuile {
  position: absolute;
  width: 100%;
  margin: 41px 0;
  z-index: 1000;
  font-family: 'Comfortaa'  !important;
  text-shadow: $fond-acces-connexion;
}

.simulation-page .tuile .titre-tuile.bottom {
  bottom: 0;
}

.simulation-page .tuile .titre-tuile h2 {
  font-size: 2.2rem;
  text-shadow: 1px 1px 2px #000000;
}

.simulation-page .tuile .tuile-bas:hover,
.simulation-page .tuile .tuile-haut img:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.simulation-page .highcharts-button {
  display: none;
}

#select-order {
  width: 150px;
}

.simulation-page .card-img-red {
  position: relative;
}

.simulation-page .card-img-red:before {
  border-radius: 5px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,0,0, 0.3);
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

@media (min-width: 768px) {
  .simulation-page .tuile {
    height: 185px;
  }

  .simulation-page .list-annonces .item-annonce {
    min-height: 400px;
  }
}

@media (min-width: 992px) {
  .simulation-page .tuile {
    height: 163px;
  }

  .simulation-page .list-annonces .item-annonce {
    min-height: 360px;
  }

  .simulation-page .list-annonces .item-annonce .simulation-liens {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 1.4em;
  }
}

@media (min-width: 1200px) {
  .simulation-page .tuile {
    height: 201px;
  }

  .simulation-page .list-annonces .item-annonce {
    min-height: 320px;
  }
}
